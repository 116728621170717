import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { Icons, formatIconName } from '@model/common';
import { TestId } from '@components/test-ids';
import { Icon } from '@components/common/generic-icon';
import { CarouselTileProps } from './CarouselTile';
import { getHTML } from '@util/common';

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.white,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
  alignItems: 'center',
  padding: theme.custom.spacing.medium,
  border: `1px solid ${theme.custom.colors.group10.light}`,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
  color: theme.custom.colors.black,

  ['i']: {
    fontSize: '4rem',
    color: theme.custom.colors.group1.base,
    transition: `color ${theme.custom.transitions.default}`
  }
}));

const Title: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: '10px 0',
  textAlign: 'center'
}));

const Description: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  marginBottom: theme.custom.spacing.medium,
  textAlign: 'center'
}));

const TileLink = styled.div(() => ({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 'bold',
  textDecoration: 'underline',
  ['a']: {
    color: theme.custom.colors.black
  }
}));

/* ************************ *
 *    OfferTileComponent    *
 * ************************ */

export const SubCarouselTile = ({ testId, link, heading, message, icon, ctaLabel }: CarouselTileProps) => {
  return (
    <Container className={'slide-container'} data-testid={testId || TestId.dynamicCarousel.tile}>
      {icon && <Icon name={Icons[formatIconName(icon)]} />}
      <Title dangerouslySetInnerHTML={getHTML(heading)} />
      <Description dangerouslySetInnerHTML={getHTML(message)} />
      {ctaLabel && (
        <TileLink>
          <Link prefetch={false} href={link} passHref>
            {ctaLabel}
          </Link>
        </TileLink>
      )}
    </Container>
  );
};
// !!link
