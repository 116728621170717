import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { PlainLinkStyles } from '@styles/common';
import { Icons, formatIconName } from '@model/common';
import { CarouselTile as CarouselTileModel } from '@model/content';
import { TestId } from '@components/test-ids';
import { Icon } from '@components/common/generic-icon';
import { getHTML } from '@util/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CarouselTileProps extends CarouselTileModel {
  testId?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.white,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  border: `1px solid ${theme.custom.colors.group2.base}`,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
  color: theme.custom.colors.black,

  ['i']: {
    fontSize: '4rem',
    color: theme.custom.colors.group1.base,
    transition: `color ${theme.custom.transitions.default}`
  }
}));

const TitleWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.group2.base,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  color: theme.custom.colors.white,
  padding: theme.custom.spacing.medium,
  width: '100%'
}));

const Title: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  textAlign: 'center'
}));

const DescriptionWrapper = styled.div(({ theme }: ThemeProps) => ({
  flexGrow: 1,
  padding: theme.custom.spacing.medium,
  width: '100%'
}));

const Description: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  textAlign: 'center'
}));

const LinkWrapper = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.group10.lighter,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  borderTop: '1px solid #ddd',
  textAlign: 'center',
  width: '100%',
  ['a']: {
    display: 'inline-block',
    padding: theme.custom.spacing.medium,
    width: '100%',
    ...(theme.custom.typography.paragraph as any),
    color: theme.custom.colors.group2.base,
    ['i']: {
      ...(theme.custom.typography.paragraph as any)
    }
  }
}));

/* ************************ *
 *    OfferTileComponent    *
 * ************************ */

export const CarouselTile = ({ testId, link, heading, message, icon, ctaLabel }: CarouselTileProps) => {
  return (
    <Container className={'slide-container'} data-testid={testId || TestId.dynamicCarousel.tile}>
      {icon && <Icon name={Icons[formatIconName(icon)]} />}
      <TitleWrapper>
        <Title dangerouslySetInnerHTML={getHTML(heading)} />
      </TitleWrapper>
      <DescriptionWrapper>
        <Description dangerouslySetInnerHTML={getHTML(message)} />
      </DescriptionWrapper>
      {ctaLabel && (
        <LinkWrapper>
          <Link prefetch={false} href={link} passHref style={PlainLinkStyles}>
            {ctaLabel} <Icon name={Icons.ARROW_RIGHT} />
          </Link>
        </LinkWrapper>
      )}
    </Container>
  );
};
